import React from 'react';
import Image from 'next/image';
import { ThemeProvider } from 'styled-components';
import { TitleContainer, TitleContent } from './style';
import { TitleProps } from './types';
import { Skeleton } from '@atlas/ui-library/components/layout/Skeleton';
import { TitleSkeleton } from './skeleton';

export const Title = ({
	size,
	variant = 'light',
	headTag,
	text,
	children,
	className,
	image,
	isLoading = false,
}: TitleProps) => {
	if (isLoading)
		return <Skeleton renderSkeleton={() => <TitleSkeleton />} />;

	const renderChildren = text && !children ? text : children;

	return (
		<ThemeProvider theme={{ size, variant }}>
			<TitleContainer $hasImage={!!image?.src}>
				{image?.src && (
					<Image
						unoptimized
						alt={image.alt ?? ''}
						src={image.src}
						width={image.width ?? 40}
						height={image.height ?? 40}
						title={image.title}
						quality={100}
					/>
				)}
				<TitleContent as={headTag} className={className}>
					{renderChildren}
				</TitleContent>
			</TitleContainer>
		</ThemeProvider>
	);
};
