import { css } from 'styled-components';

export const ctaSecondary = css`
	display: flex;
	justify-content: center;
	align-items: center;
	border-radius: 5px;
	color: var(--color-text-onPrimary);
	background: var(--color-primary-_500);
	align-items: center;
`;
