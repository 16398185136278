import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import { media } from '@atlas/ui-library/lib/breakpoints';

const titleSizes = theme('size', {
	XXL: css`
		font-size: var(--font-title-size-XXL-mobile);
		letter-spacing: 0.25px;
		line-height: 1.1;
		${media.md`
			font-size: var(--font-title-size-XXL-tablet);
			line-height: 1.16;
		`}
		${media.lgMin`
			font-size: var(--font-title-size-XXL-laptop);
			letter-spacing: 0;
			line-height: 1.2;
		`}
	`,
	XL: css`
		font-size: var(--font-title-size-XL-mobile);
		letter-spacing: 0.25px;
		line-height: 1.18;
		${media.md`
			font-size: var(--font-title-size-XL-tablet);
			line-height: 1.17;
		`}
		${media.lgMin`
			font-size: var(--font-title-size-XL-laptop);
			letter-spacing: 0;
			line-height: 1.17;
		`}
	`,
	L: css`
		font-size: var(--font-title-size-L-mobile);
		letter-spacing: 0.25px;
		line-height: 1.17;
		${media.md`
			font-size: var(--font-title-size-L-tablet);
		`}
		${media.lgMin`
			font-size: var(--font-title-size-L-laptop);
			line-height: 1.2;
		`}
	`,
	M: css`
		font-size: var(--font-title-size-M-mobile);
		letter-spacing: 0.25px;
		line-height: 1.19;
		${media.md`
			font-size: var(--font-title-size-M-tablet);
		`}
		${media.lgMin`
			font-size: var(--font-title-size-M-laptop);
			line-height: 1.25;
		`}
	`,
	S: css`
		font-size: var(--font-title-size-S-mobile);
		letter-spacing: 0.15px;
		line-height: 1.18;
		${media.md`
			font-size: var(--font-title-size-S-tablet);
		`}
		${media.lgMin`
			font-size: var(--font-title-size-S-laptop);
			letter-spacing: 0;
			line-height: 1.33;
		`}
	`,
	XS: css`
		font-size: var(--font-title-size-S-mobile);
		letter-spacing: 0.1px;
		line-height: 1.21;
		${media.md`
			font-size: var(--font-title-size-XS-tablet);
		`}
		${media.lgMin`
			font-size: var(--font-title-size-XS-laptop);
			letter-spacing: 0.15px;
			line-height: 1.5;
		`}
	`,
});

const titleTheme = theme('variant', {
	light: css`
		color: var(--color-primary-_900);
	`,
	black: css`
		color: var(--color-basic-black);
	`,
	lightBlack: css`
		color: color-mix(
			in srgb,
			var(--color-basic-black)
				var(--opacity-emphasis-surface-medium),
			transparent
		);
	`,
	dark: css`
		color: var(--color-basic-white);
	`,
});

const TitleContainer = styled.div<{
	$hasImage: boolean;
}>`
	&:not(:first-of-type) {
		margin-top: var(--spacing-_32);
	}
	width: 100%;

	&:has(h1) + &:has(h2) {
		margin-top: 0px;
	}

	&:has(h2) + &:has(h3) {
		margin-top: 8px;
	}

	&:has(h3) + &:has(h4) {
		margin-top: 8px;
	}

	${({ $hasImage }) =>
		$hasImage
			? `
	display: flex;
	align-items: center;
	gap: var(--spacing-_8);
	margin-bottom: var(--spacing-_16);
	width: 100%;`
			: `
		margin-bottom: var(--spacing-_24)}
	`}
`;

const TitleContent = styled.h1`
	${titleSizes}
	${titleTheme}
  	font-family: var(--font-title-family-regular);
	font-weight: 600;
	margin: auto 0;
`;

export { TitleContainer, TitleContent };
