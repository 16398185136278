import React from 'react';
import { ThemeProvider } from 'styled-components';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';
import { BodyContent } from './style';
import { BodyProps } from './types';
import { Title } from '../Title';
import { Skeleton } from '@atlas/ui-library/components/layout/Skeleton';
import { BodySkeleton } from './skeleton';
import { useLocale } from 'next-intl';
import { isAbsoluteUrl } from '@atlas/ui-library/lib/isAbsoluteUrl';

export const Body = ({
	variant = 'light',
	size,
	text,
	children,
	className,
	margin = 'default',
	isLoading = false,
}: BodyProps) => {
	const locale = useLocale();

	if (isLoading)
		return <Skeleton renderSkeleton={() => <BodySkeleton />} />;

	const renderChildren =
		text && !children ? text : (children as string); // @TODO TS error during a complicated merge, this shuts up TS complains 🤷‍♂️

	return (
		<ThemeProvider theme={{ variant, size, margin }}>
			<BodyContent className={className}>
				<Markdown
					rehypePlugins={[rehypeRaw]}
					components={{
						// Map Headings to corresponding Styled-component.
						h1: ({ node, ...props }) => (
							<Title
								size={'XL'}
								variant={variant}
								{...props}
								headTag={'h1'}
							/>
						),
						h2: ({ node, ...props }) => (
							<Title
								size={'L'}
								variant={variant}
								{...props}
								headTag={'h2'}
							/>
						),
						h3: ({ node, ...props }) => (
							<Title
								size={'M'}
								variant={variant}
								{...props}
								headTag={'h3'}
							/>
						),
						h4: ({ node, ...props }) => (
							<Title
								size={'S'}
								variant={variant}
								{...props}
								headTag={'h4'}
							/>
						),
						a: ({ node, href, ...props }: any) => {
							return (
								<a
									href={
										isAbsoluteUrl(href)
											? href
											: `/${locale}${href}`
									}
									{...props}
								/>
							);
						},
					}}>
					{renderChildren}
				</Markdown>
			</BodyContent>
		</ThemeProvider>
	);
};
