import styled, { css } from 'styled-components';
import theme from 'styled-theming';
import Link from 'next/link';
import { preset } from './style.default';
import { outlined } from './style.outlined';
import { cta } from './style.cta';
import { unstyled } from './style.unstyled';
import { ctaSecondary } from './style.ctaSecondary';

const size = theme('size', {
	XL: css`
		font-size: var(--font-body-size-XL-mobile);
		padding: 20px 50px;
		font-weight: bold;
	`,
	L: css`
		font-size: var(--font-body-size-XL-mobile);
		padding: 16px 24px;
		height: 58px;
	`,
	M: css`
		font-size: var(--font-body-size-L-mobile);
		padding: 12px 16px;
		height: 48px;
	`,
	S: css`
		font-size: var(--font-body-size-M-mobile);
		padding: 8px 12px;
		height: 40px;
	`,
});

const variant = theme('variant', {
	cta,
	outlined,
	unstyled,
	ctaSecondary,
	default: preset,
});

const LinkContent = styled(Link)`
	border: none;
	cursor: pointer;
	font-family: var(--font-body-family-regular);
	font-weight: bold;
	margin-left: auto;
	margin-right: auto;
	width: fit-content;
	text-align: center;
	text-decoration: none;
	white-space: normal;

	&:hover {
		text-decoration: none;
	}

	${size}
	${variant}
`;

export { LinkContent };
